import React, { useEffect, useState } from 'react';
import { Fab, Grid, Card, CardMedia, CardContent, CardActionArea, CardActions, Chip, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { AddCircle, Add, Edit, Delete } from '@material-ui/icons';
import API from "../../services/API";
import Input from "../../components/Input";
import {useSnackbar} from 'notistack';  
import DragAndDrop from "../../components/Drag&Drop";
import LabelsPicker from "../../components/LabelsPicker";
import { Link } from 'react-router-dom';

const actions={
    Créer:{verbe:"Créer",action:"création",Action:"Création"},
    Modifier:{verbe:"Modifier",action:"modification",Action:"Modification"},
}

export default function Createurs({}){
     const [createurs, setCreateurs] = useState([])
     const [refresh, setRefresh] = useState(true)
     const [popupOpen, setPopupOpen] = useState(false)
     const [etiquettesExistantes, setEtiquettesExistantes] = useState([])
     
     useEffect(()=>{
        if(!refresh) return
        setRefresh(false)
        API("GET","createur") 
        .then(res=>setCreateurs(res)) 

        API("GET","etiquette")
        .then(res=>setEtiquettesExistantes(res))
    },[refresh])

    useEffect(()=>{
        console.log(createurs)
    },[createurs])

    const resetPopup = ()=>{
        setPopupOpen(false)
        setRefresh(true)
    } 

    return(
        <>
            <Fab color="primary" style={{position: 'absolute', zIndex: 100, bottom: 16, right: 16}} onClick={()=>setPopupOpen(true)} ><Add /></Fab>
            <Grid container spacing={2}>
                {createurs.map(createur=>(
                    <Grid key={createur.id} item md={3} xs={6} >
                        <Card>
                            <CardActionArea component={Link}  to={"/createur/"+createur.id}>
                                <CardMedia image={(createur.LogoUrl !==null)?"../"+createur.LogoUrl:"../images/no-image.png"} style={{height:0, paddingTop:"100%"}} />
                                <CardContent>
                                    <h2>{createur.Nom}</h2>
                                    {/*createur.Etiquettes && createur.Etiquettes.map(e=>(
                                        <Chip key={e.id} label={e.Nom} />
                                    ))*/}
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid> 
                ))}
            </Grid>
            {popupOpen && <PopupGenerale createur={undefined} etiquettes={etiquettesExistantes} resetPopup={resetPopup} /> }
        </> 
    )
}


export function PopupGenerale({createur, etiquettes, resetPopup}){
    const [reponses, setReponses] = useState(createur || {Nom:undefined})
    const [reponsesEtiquettes, setReponsesEtiquettes] = useState((createur&&createur.Etiquettes) || [])

    const isCreation = createur === undefined
    const action = (isCreation)?actions.Créer:actions.Modifier
    
    const { enqueueSnackbar } = useSnackbar();

    const handleChange = (key, value)=>{
        setReponses(curr=>({...curr,[key]:value}))
    }

    const repChanged = isCreation || (createur.Nom!==reponses.Nom || createur.Adresse!==reponses.Adresse || reponses.image!==undefined)
    const repEtiquettesChanged = !LabelsAreEqual((createur&&createur.Etiquettes) || [],reponsesEtiquettes)
    const formValid =   (isCreation && Object.values(reponses).reduce((acc,x)=>acc&&x!==undefined, true)) || 
                        (!isCreation && (repChanged || repEtiquettesChanged))

    const submitForm = async (event)=>{

        let promisesHigh = []
        if(isCreation){
            await API("POST", "createur", reponses, "", "multipart/form-data")
            .then(res=>{
                if(res.messageFr){
                    enqueueSnackbar("Erreur lors de la "+action.action+" du créateur : "+res.messageFr,{variant: "error"})
                }
                Object.assign(reponses,{id:res.insertId})
            })
        }else if(repChanged){
            promisesHigh.push(
                API((isCreation)?"POST":"PATCH", "createur", reponses, "", "multipart/form-data")
                .then(res=>{
                    if(res.messageFr){
                        enqueueSnackbar("Erreur lors de la "+action.action+" du créateur : "+res.messageFr,{variant: "error"})
                    }
                })
            )
        }
        if(isCreation || repEtiquettesChanged){
            let promises = []
            reponsesEtiquettes.filter(r=>!r.id).map(r=>{
                promises.push(
                    API("POST","etiquette",r)
                    .then(res=>{
                        if(res.messageFr){
                            enqueueSnackbar("Erreur lors de la création des étiquettes : "+res.messageFr,{variant: "error"})
                            return
                        } 
                        Object.assign(r,{id:res.insertId})  
                    })
                )
            })
            Promise.all(promises).then(x=>{
                const aAjouter = (isCreation) ? reponsesEtiquettes.map(e=>e.id) : reponsesEtiquettes.map(e=>e.id).filter(x => !createur.Etiquettes.map(e=>e.id).includes(x));
                const aSupprimer = (isCreation) ? [] : createur.Etiquettes.map(e=>e.id).filter(x => !reponsesEtiquettes.map(e=>e.id).includes(x));
                
                if(aAjouter.length){
                    promisesHigh.push(
                        API("POST","createur_etiquette",{Createur_id:reponses.id, Etiquettes_id:aAjouter})
                        .then(res=> {
                            if(res.messageFr){
                                enqueueSnackbar("Erreur lors de la création de certaines createurs_étiquettes : "+res.messageFr,{variant: "error"})
                                return
                            } 
                        })
                    )
                }
                if(aSupprimer.length){
                    promisesHigh.push(
                        API("DELETE","createur_etiquette",{Createur_id:createur.id, Etiquettes_id:aSupprimer})
                        .then(res=>{
                            if(res.messageFr){
                                enqueueSnackbar("Erreur lors de la suppression de certaines étiquettes : "+res.messageFr,{variant: "error"})
                                return
                            } 
                        })
                    )
                }
                Promise.all(promisesHigh).then(x=>{
                    resetPopup()
                    enqueueSnackbar((isCreation?"Ajout":"Modification")+" du créateur réussie",{variant: "success"})
                })
            }) 
        }else{
            Promise.all(promisesHigh).then(x=>{
                resetPopup()
                enqueueSnackbar((isCreation?"Ajout":"Modification")+" du créateur réussie",{variant: "success"})
            })
        }
    }

    return(
        <Dialog open={true} aria-labelledby="popup" fullWidth={true} maxWidth='md'>
            <DialogTitle id="popup">{action.verbe} un créateur</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Input name="Nom" defaultValue={!isCreation&&createur.Nom ||""} required fullWidth onChange={v=>handleChange("Nom",v)} />
                        <Input name="Adresse" defaultValue={!isCreation&&createur.Adresse ||""} multiline fullWidth onChange={v=>handleChange("Adresse",v)} />
                        <LabelsPicker initialLabels={etiquettes} initialSelectedLabels={!isCreation&&createur.Etiquettes ||[]} allowCreation onChange={setReponsesEtiquettes} />
                    </Grid>
                    <Grid item xs={6}>
                        Logo
                        <DragAndDrop typeFichier="image/*" multiple={false} urlBackground={!isCreation&& !reponses.image && "../"+createur.LogoUrl} onChange={v=>setReponses(currVal=>({...currVal, ...v[0]}))} /> 
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={resetPopup} variant="contained" color="secondary">
                    Annuler
                </Button>
                <Button disabled={!formValid} onClick={submitForm} variant="contained" color="primary" autoFocus>
                    {action.verbe}
                </Button>
            </DialogActions>
        </Dialog>
    )
}




function LabelsAreEqual(labels1, labels2) {
    if (labels1.length !== labels2.length) {
      return false;
    }
    for(var k = 0; k < labels1.length; k++) {
        if (labels1[k].id !== labels2[k].id) {
            return false;
        }
    }
    return true;
}
