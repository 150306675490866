import React, { useState, useCallback, useEffect } from 'react';
import { AppBar, Tabs, Tab, Typography, Box, Container, Divider } from '@material-ui/core';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import { Search } from '@material-ui/icons';  
import './App.css';
import {SnackbarProvider} from 'notistack';

import Gallerie from './scenes/Gallerie/Gallerie';
import Createurs from './scenes/Createurs/Createurs'; 
import FicheCreateur from './scenes/FicheCreateur/FicheCreateur';



export default function App() {
    return ( 
        <SnackbarProvider maxSnack={5}>
            <Router>
                <Switch>
                    <Route exact path="/" component={PagePrincipale}/>
                    <Route exact path="/Createur" render={()=><PagePrincipale defaultTab={2}/>}/>
                    <Route path="/Createur/:idCreateur" children={<FicheCreateur />} />
                </Switch>
            </Router>
        </SnackbarProvider>
    ); 
}


function PagePrincipale({defaultTab}){
    const [value, setValue] = React.useState(defaultTab||0);

    const handleChange = (event, newValue) => {
        setValue(newValue); 
    }; 

    return ( 
        <Container maxWidth="md">
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} variant="fullWidth" aria-label="simple tabs example">
                    <Tab label={<><div><Search style={{marginBottom:-5}} />RECHERCHE</div></>}  />
                    <CustomDivider/>
                    <Tab label="Créateurs" />
                </Tabs> 
            </AppBar>
            <div hidden={value!==0}>
                <Box p={3}>
                    <Gallerie/>
                </Box>
            </div>
            <div hidden={value!==2} style={{position: "relative"}}>
                <Box p={3}>
                    <Createurs/>
                </Box>
            </div>
        </Container>
    )
}

function CustomDivider(props){
    return(
        <Divider orientation="vertical" flexItem style={{backgroundColor: "white", marginTop: 10, marginBottom: 10}} />
    )
}