import React, { useEffect, useState } from "react";
import { Button, Container, Icon, GridList, GridListTile, Fab, Paper, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { AddCircle, Add, Edit, Delete, ArrowBackIos } from '@material-ui/icons';
import { useParams } from "react-router-dom";
import MaterialTable from 'material-table'
import API from "../../services/API";
import Input from "../../components/Input";
import {useSnackbar} from 'notistack';
import BlocContacts from "./components/BlocContacts";
import BlocEntretiens from "./components/BlocEntretiens";
import BlocPieces from "./components/BlocPieces";
import BlocGeneral from "./components/BlocGeneral";
import { Link, useHistory, Redirect  } from 'react-router-dom';


export default function FicheCreateur({}){
    let { idCreateur } = useParams()
    const [createur, setCreateur] = useState({})
    const [refresh, setRefresh] = useState(true)
    const [etiquettesExistantes, setEtiquettesExistantes] = useState([])
    const [open, setOpen] = useState(false)
    const [done, setDone] = useState(false)

    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        if(!idCreateur || !refresh) return
        setRefresh(false)
        API("GET","createur/"+idCreateur+"/complete")
        .then(res=>{
            console.log(res)
            setCreateur(res)
            const promises = []
            res.Pieces.map(p=>{
                promises.push(API("GET","etiquette","",{Image_id:p.id})
                .then(res2=>({
                    ...p,
                    Etiquettes:res2
                })))
            })

            Promise.all(promises).then((ret)=>{setCreateur(curr=>({...curr,Pieces:ret}))})
        })
        API("GET","etiquette")
        .then(res=>setEtiquettesExistantes(res))
    },[idCreateur, refresh])

    const handleDelete = (id)=>{
        API("DELETE","createur",{id:id})
        .then(res=>{
            if(res.messageFr){//ERREUR
                enqueueSnackbar("Erreur lors de la suppression du créateur",{variant: "error"})
            }else{
                enqueueSnackbar("Suppression du créateur réussie",{variant: "success"})
                setDone(true)
            }
        })
    }

    return(
        <>
            <Container maxWidth="md">
                <Fab color="primary"  component={Link} to="../.." ><ArrowBackIos /></Fab>
                <span style={{display:"flex"}}>
                    <h1 style={{width:"calc(100% - 56px)"}}>{createur.Nom}</h1>
                    <Fab color="secondary" style={{width:56}}  onClick={()=>setOpen(true)}><Delete /></Fab>
                </span>
                
                <BlocGeneral createur={createur} etiquettes={etiquettesExistantes} setReload={setRefresh} />
                <BlocContacts idCreateur={idCreateur} contacts={createur.Contacts} setReload={setRefresh} />
                <BlocEntretiens idCreateur={idCreateur} entretiens={createur.Entretiens} setReload={setRefresh} />
                <BlocPieces createur={createur} pieces={createur.Pieces} etiquettes={etiquettesExistantes} setReload={setRefresh} />
            </Container>

            <Dialog open={open} onClose={()=>setOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Etes-vous sûr ?</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Vous êtes en train supprimer un créateur.
                    Si vous validez, tous les éléments en lien avec ce créateur seront aussi supprimés (pièces, contacts, entretiens,...)
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>setOpen(false)} color="primary">
                    Annuler
                </Button>
                <Button onClick={()=>handleDelete(createur.id)} color="primary" autoFocus>
                    Valider
                </Button>
                </DialogActions>
            </Dialog>
            {done && <Redirect to="/createur" />}
        </>
    )    
}


