export default function API (method = 'GET', path='', params='', querys={}, type='application/json'){
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("key", "ki99=9!2?^8|DVUWzwzX");
    var myInit = {
        method: method
    };

    if(method==="POST" || method==="PATCH" || method==="DELETE"){
        if(type ==="application/json"){
            myInit={...myInit,headers:myHeaders, body: JSON.stringify(params)}
        }else if(type ==="multipart/form-data"){
            var data = new FormData()
            data.append("image",params.image)
            delete params.image
            for (const [key, value] of Object.entries(params)) {
                data.append(key,JSON.stringify(value))
            }
            myInit={...myInit, body: data}
        }
    }

    const url = new URL("http://appli-createurs-api.pauletteginette.com/"+path);

    Object.keys(querys).forEach(key => {
        if(querys[key]){
            if(typeof(querys[key]!="string")) querys[key] = JSON.stringify(querys[key])
            url.searchParams.append(key, querys[key])
        }})
    return fetch(url, myInit)
    .then(response => {
        if(response.status === 200 || response.status === 201){
            return response.json();
        }else if(response.status === 204){
            return []
        }else{
            //console.log("%cErreur "method + " - " + path)
            //return response.text();
            return response.json();
        }
    });
}