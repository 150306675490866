import React, {useState, useEffect} from 'react';
import { TextField } from '@material-ui/core';


export default function Input({name, defaultValue, multiline=false, required, InputProps, onChange, disabled=false, fullWidth=false}){
    const [value, setValue] = useState(defaultValue)
    const [error, setError] = useState(false)

    useEffect(()=>{
        setValue(defaultValue)
    },[defaultValue])

    const handleChange=(event)=>{
        const v = event.target.value
        setValue(v)
        if(required && v ===""){
            setError(true)
            onChange(undefined)
        }else{
            setError(false)
            onChange(v)
        }
    } 
    return (
        <TextField
            /*id={id}*/
            label={name}
            value={value}
            InputProps={InputProps}
            variant="outlined"
            onChange={handleChange}
            required={required}
            error={error}
            disabled={disabled}
            multiline={multiline}
            fullWidth={fullWidth}
        />
    )
}




    /*{endAdornment: <InputAdornment position="end">{produit.uniteMesure}</InputAdornment>,
    inputComponent: NumberFormatCustom}*/
