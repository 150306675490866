import React, { useEffect, useState } from "react";
import { Button, Fab, Paper, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Add, Edit, Delete } from '@material-ui/icons';
import MaterialTable from 'material-table'
import API from "../../../services/API";
import Input from "../../../components/Input";
import {useSnackbar} from 'notistack';  



export default function BlocContacts({idCreateur, contacts, setReload}){
    const [popupOpen, setPopupOpen] = useState(false)
    const [popupData, setPopupData] = useState(undefined)

    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        { field: 'id', title: 'ID', hidden:true},
        { field: 'Prenom', title: 'Prénom' },
        { field: 'Nom', title: 'Nom' },
        { field: 'Fonction', title: 'Fonction' },
        { field: 'Telephone', title: 'Téléphone' },
        { field: 'Email', title: 'Email' },
    ];
    const actions=[{
        icon:()=><Edit/>,
        iconProps:{color:'primary'} ,
        tooltip:'Editer ligne',
        onClick:(event, rowData)=>handleModify(rowData)
    },{
        icon:()=><Delete/>,
        iconProps:{color:'primary'} ,
        tooltip:'Supprimer ligne',
        onClick:(event, rowData)=>handleDelete(rowData)
    }]

    const handleModify = (data)=>{
        setPopupData(data)
        setPopupOpen(true)
    }

    const handleDelete = (data)=>{
        API("DELETE","Contact",{id:data.id})
        .then(res=>{
            if(res.messageFr){//ERREUR
                enqueueSnackbar("Erreur lors de la suppression",{variant: "error"})
            }else{
                enqueueSnackbar("Suppression réussie",{variant: "success"})
            }
            resetPopup()
        })
    }

    const resetPopup = ()=>{
        setPopupData(undefined)
        setPopupOpen(false)
        setReload(true)
    }
    

    return(
        <>
            <Paper style={{position:"relative"}} >
                <Fab color="primary" style={{position: 'absolute', zIndex: 100, top: 10, right: 10}} onClick={()=>setPopupOpen(true)} ><Add/></Fab>
                <h2>Contacts</h2>
                <MaterialTable data={contacts} columns={columns} options={{search: false, showTitle:false, paging:false}} actions={actions}/>
            </Paper>
            {popupOpen && <PopupCreationContact initialValues={popupData} idCreateur={idCreateur} resetPopup={resetPopup} /> }
        </>
    )
}

function PopupCreationContact({initialValues, idCreateur, resetPopup}){
    const [reponses, setReponses] = useState(initialValues || {Createur_id:idCreateur})
    
    const { enqueueSnackbar } = useSnackbar();

    const handleChange = (key, value)=>{
        setReponses(curr=>({...curr,[key]:value}))
    }

    const formValid = (initialValues===undefined && Object.values(reponses).reduce((acc,x)=>acc&&x!==undefined, true)) || (initialValues!==undefined && !isEqual(initialValues,reponses))

    const submitForm = (event)=>{
        const methode = (initialValues===undefined)?"POST":"PATCH"
        API(methode, "contact", reponses)
        .then(res=>{
            if(res.messageFr){
                enqueueSnackbar("Erreur lors de la création du contact : "+res.messageFr,{variant: "error"})
            }else{
                enqueueSnackbar("Création du contact réussie",{variant: "success"})
            }
            resetPopup()
        })
    }

    return(
        <Dialog open={true} aria-labelledby="popup" fullWidth={true} maxWidth='md'>
            <DialogTitle id="popup">Ajouter une image</DialogTitle>
            <DialogContent>
                <Input name="Prénom" defaultValue={initialValues && initialValues.Prenom} onChange={(value)=>handleChange("Prenom", value)} />
                <Input name="Nom" defaultValue={initialValues && initialValues.Nom} onChange={(value)=>handleChange("Nom", value)} />
                <Input name="Fonction" defaultValue={initialValues && initialValues.Fonction} onChange={(value)=>handleChange("Fonction", value)} />
                <Input name="Téléphone" defaultValue={initialValues && initialValues.Telephone} onChange={(value)=>handleChange("Telephone", value)} />
                <Input name="Email" defaultValue={initialValues && initialValues.Nom} onChange={(value)=>handleChange("Email", value)} />
            </DialogContent>
            <DialogActions>
                <Button onClick={resetPopup} variant="contained" color="secondary">
                    Annuler
                </Button>
                <Button disabled={!formValid} onClick={submitForm} variant="contained" color="primary" autoFocus>
                    Ajouter
                </Button>
            </DialogActions>
        </Dialog>
    )
}




function isEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
  
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  }