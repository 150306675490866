import React, { useEffect, useState } from "react";
import { Button, Fab, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Card, CardMedia, CardContent, CardActions, IconButton, Chip } from '@material-ui/core';
import { Add, Edit, Delete } from '@material-ui/icons';
import MaterialTable from 'material-table'
import API from "../../../services/API";
import Input from "../../../components/Input";
import DragAndDrop from '../../../components/Drag&Drop';
import Dropdown from '../../../components/Dropdown'
import LabelsPicker from '../../../components/LabelsPicker';
import {useSnackbar} from 'notistack';  



export default function BlocPieces({createur, pieces, etiquettes, setReload}){
    const [popupOpen, setPopupOpen] = useState(false)
    const [popupData, setPopupData] = useState(undefined)

    const { enqueueSnackbar } = useSnackbar();

    const resetPopup = ()=>{
        setPopupData(undefined)
        setPopupOpen(false)
        setReload(true)
    }
    
    useEffect(()=>{
        console.log(popupData)
    },[popupData])

    return(
        <>
            <Paper style={{position:"relative"}} >
                <Fab color="primary" style={{position: 'absolute', zIndex: 100, top: 10, right: 10}} onClick={()=>setPopupOpen(true)} ><Add/></Fab>
                <h2>Pièces</h2>
                <Grid container spacing={2}>
                    {pieces && pieces.map(p=>(
                        <Piece key={p.id} piece={p} setPopupData={setPopupData} setPopupOpen={setPopupOpen} resetPopup={resetPopup} />
                    ))}
                </Grid>
            </Paper>
            {popupOpen && <PopupPiece initialValues={popupData} createur={createur} etiquettes={etiquettes} resetPopup={resetPopup} /> }
        </>
    )
}

function Piece({piece, setPopupData, setPopupOpen, resetPopup}){
    
    const { enqueueSnackbar } = useSnackbar();

    const handleModify = (data)=>{
        setPopupData(data)
        setPopupOpen(true)
    }

    const handleDelete = (data)=>{
        debugger
        API("DELETE","Image",data)
        .then(res=>{
            if(res.messageFr){//ERREUR
                enqueueSnackbar("Erreur lors de la suppression : "+res.messageFr,{variant: "error"})
            }else{
                enqueueSnackbar("Suppression réussie",{variant: "success"})
            }
        })
    }
    return(
        <Grid item md={4} xs={6} >
            <Card >
                <CardMedia image={"../"+piece.Url} style={{height:0, paddingTop:"100%"}} />
                <CardContent>
                    {piece.Etiquettes && piece.Etiquettes.map(e=>(
                        <Chip key={e.id} label={e.Nom} />
                    ))}
                </CardContent>
                <CardActions disableSpacing>
                    <IconButton aria-label="Editer pièce" onClick={()=>handleModify(piece)} ><Edit /></IconButton>
                    <IconButton aria-label="Supprimer" onClick={()=>handleDelete(piece)} ><Delete /></IconButton>
                </CardActions>
            </Card>
        </Grid>
    )
}

function PopupPiece({initialValues, createur, etiquettes, resetPopup}){
    const isCreation = initialValues===undefined 
    const [reponsesImage, setReponsesImage] = useState(initialValues || {image:undefined, Createur_id:createur.id})
    const [reponsesEtiquettes, setReponsesEtiquettes] = useState(initialValues && initialValues.Etiquettes || [])
    
    const { enqueueSnackbar } = useSnackbar();



    useEffect(()=>{
        console.log(initialValues)
    },[initialValues])

    const formValid = (isCreation && Object.values(reponsesImage).reduce((acc,x)=>acc&&x!==undefined, true)) || (!isCreation && (reponsesImage.image!==undefined || !ArrayIsEqual(initialValues.Etiquettes.map(x=>x.id),reponsesEtiquettes.map(x=>x.id))))

    const submitForm = async (event)=>{
        let imageId
        if(isCreation){
            await API("POST", "image", reponsesImage, "", "multipart/form-data")
            .then(res=>{
                if(res.messageFr){
                    enqueueSnackbar("Erreur lors de la création de la pièce : "+res.messageFr,{variant: "error"})
                    return 
                }
                imageId = res.insertId
            })
        }else{
            imageId = reponsesImage.id
            if(reponsesImage.image!==undefined){  //si une nouvelle image a été chargé
                API("PATCH", "image", reponsesImage, "", "multipart/form-data")
                .then(res=>{
                    if(res.messageFr){
                        enqueueSnackbar("Erreur lors de la mise à jour de la pièce : "+res.messageFr,{variant: "error"})
                        return 
                    }
                })
            }
        }

        let promises = []
        reponsesEtiquettes.filter(r=>!r.id).map(r=>{
            promises.push(
                API("POST","etiquette",r)
                .then(res=>{
                    if(res.messageFr){
                        enqueueSnackbar("Erreur lors de la création des étiquettes : "+res.messageFr,{variant: "error"})
                        return
                    } 
                    Object.assign(r,{id:res.insertId})  
                })
            )
        })
        Promise.all(promises).then(x=>{
            const aAjouter = (isCreation) ? reponsesEtiquettes : reponsesEtiquettes.filter(x => !initialValues.Etiquettes.map(e=>e.id).includes(x.id));
            const aSupprimer = (isCreation) ? [] : initialValues.Etiquettes.filter(x => !reponsesEtiquettes.map(e=>e.id).includes(x.id));

            if(aAjouter.length){
                API("POST","image_etiquette",{Image_id:imageId, Etiquettes_id:aAjouter.map(e=>e.id)})
                .then(res=> {
                    if(res.messageFr){
                        enqueueSnackbar("Erreur lors de la création des images_étiquettes : "+res.messageFr,{variant: "error"})
                        return
                    } 
                })
            }
            if(aSupprimer.length){
                API("DELETE","image_etiquette",{Createur_id:createur.id, Etiquettes_id:aSupprimer})
                .then(res=>{
                    if(res.messageFr){
                        enqueueSnackbar("Erreur lors de la suppression de certaines étiquettes : "+res.messageFr,{variant: "error"})
                        return
                    } 
                })
            }
            resetPopup()
            enqueueSnackbar((isCreation?"Ajout":"Modification")+" de la pièce réussie",{variant: "success"})
        }) 
    }

    return(
        <Dialog open={true} aria-labelledby="popup" fullWidth={true} maxWidth='md'>
            <DialogTitle id="popup">Ajouter une pièce</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <LabelsPicker initialLabels={etiquettes} initialSelectedLabels={initialValues&&initialValues.Etiquettes||[]} fixedLabels={etiquettes.filter(e=>e.Nom===createur.Nom)} allowCreation={true} onChange={setReponsesEtiquettes} />
                    </Grid>
                    <Grid item xs={6}>
                        <DragAndDrop typeFichier="image/*" multiple={false} onChange={v=>setReponsesImage(currVal=>({...currVal,...v[0]}))} /> 
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={resetPopup} variant="contained" color="secondary">
                    Annuler
                </Button>
                <Button disabled={!formValid} onClick={submitForm} variant="contained" color="primary" autoFocus>
                    Ajouter
                </Button>
            </DialogActions>
        </Dialog>
    )
}




function ArrayIsEqual(array1, array2) {  
    if (array1.length !== array2.length) {
      return false;
    }
    for(var k = 0; k < array1.length; k++) {
        if (array1[k] !== array2[k]) {
            return false;
        }
    }
    return true;
}