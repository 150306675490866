import React, { useState, useEffect, useMemo } from 'react';
import {useDropzone} from 'react-dropzone'


const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    //backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    minHeight: "calc(100% - 40px)"
  };
  
  const activeStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };

  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    //marginBottom: 8,
    //marginRight: 8,
    //width: 100,
    //height: 100,
    padding: 4,
    boxSizing: 'border-box'
  };
  const thumbInner = {
    //display: 'flex',
    //minWidth: 0,
    //overflow: 'hidden'
  };
  
  const img = {
    display: 'block',
    maxWidth: "100%",
    maxHeight: 250
  };
  
export default function DragAndDrop({typeFichier, multiple, onChange, urlBackground}) {
    const [files, setFiles] = useState([]);

    const {
      //acceptedFiles,
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject
    } = useDropzone({
        accept: typeFichier, 
        multiple:multiple, 
        onDrop: acceptedFiles => {
            if(typeFichier === 'image/*'){
                setFiles(acceptedFiles.map(file => Object.assign(file, {preview: URL.createObjectURL(file)})))
                /*setFiles(acceptedFiles.map(file => {
                    const image = new Image();
                    image.addEventListener('load', () => {
                        Object.assign(file, {preview: image.src, width:image.width, height: image.height});
                    })
                    image.src = URL.createObjectURL(file)
                }))*/
                /*acceptedFiles.forEach(file => {
                    const image = new Image();
                    image.addEventListener('load', () => {
                        Object.assign(file, {preview: image.src, width:image.width, height: image.height});
                    })
                    image.src = URL.createObjectURL(file)
                })*/

            }
        }
    });
  
    const style = useMemo(() => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }), [
      isDragActive, 
      isDragReject,
      isDragAccept
    ]);
  
    /*const files = acceptedFiles.map(file => (
      <li key={file.path}>
        {file.path} - {file.size} octets
      </li>
    ));*/

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
          <div style={thumbInner}>
            <img
              src={file.preview}
              style={img}
            />
          </div>
        </div>
      ));

      useEffect(() => {
        if(!files || !files.length) return
        let promises = []
        
        files.forEach(file => {
            const image = new Image();
            promises.push(new Promise((resolve, reject)=>{
                image.addEventListener('load', () => {
                    resolve({image:file, width:image.width, height:image.height})
                //Object.assign(file, {preview: image.src, width:image.width, height: image.height});
                //debugger
                })
            }))
            image.src = file.preview
            //URL.revokeObjectURL(file.preview)   // Make sure to revoke the data uris to avoid memory leaks
        });
        Promise.all(promises).then(x=>{
            onChange(x)
        })
        
      }, [files]);
  
    return (
      <div className="container" style={{maxHeight:250, height:"100%", backgroundImage: "url("+urlBackground+")", backgroundSize:"contain", backgroundRepeat:"no-repeat", backgroundPosition:"center"}}>
        <div {...getRootProps({style})}>
            <input {...getInputProps()} /> 
            <p style={{position:"absolute", margin: 0, top:"50%"}}>Glisser-déposer ici ou cliquer</p>
            {!multiple && typeFichier === 'image/*' && 
                thumbs
            }
        </div>
        {typeFichier !== 'image/*' && 
            <aside>
              <h4>Fichiers</h4>
              <ul>{files}</ul>
            </aside>
        }
      </div>
    );
}
  

/* <div style={thumbsContainer}>
                    {thumbs}
                </div>*/