import React, {useEffect, useState} from "react";
import { TextField, Chip } from "@material-ui/core";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import DoneIcon from "@material-ui/icons/Done";

const filter = createFilterOptions();

export default function LabelsPickerC({initialLabels, onChange, allowCreation, fixedOptions}) {
    const [value, setValue] = useState([]);
    //const [newLabels, setNewLabels] = useState([]);
    const [labels, setLabels] = useState(initialLabels);

    useEffect(()=>{
        setLabels(initialLabels)
    },[initialLabels])

    /*useEffect(()=>{
        setValue(currValue=>[...fixedOptions, ...currValue.filter(option=>fixedOptions.indexOf(option) === -1)])
    },[fixedOptions])*/

    useEffect(()=>{
        onChange(value)
    },[value])
  
    return (
        <Autocomplete
            id="labelsPicker"
            multiple
            onChange={(event, selectedValues) => {
                setValue([...fixedOptions, ...selectedValues.filter(option=>fixedOptions.indexOf(option) === -1)]);
                const nL = selectedValues.filter((val) => !val.id);
                setLabels([...initialLabels, ...nL]);
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if(allowCreation && params.inputValue !== "" && !filtered.map((r) => r.Nom).includes(params.inputValue)) { // Suggest the creation of a new value
                    filtered.push({
                        Nom: params.inputValue
                    });
                }
                return filtered;
            }}
            options={[...labels].sort((a, b) => {
                if(a.type !== b.type){
                    return (a.type==="standard")?-1:1
                }
                // Display the selected labels first.
                let ai = value.indexOf(a);
                ai = (ai !== -1) ? ai : value.length + labels.indexOf(a);
                let bi = value.indexOf(b);
                bi = (bi!==-1)? bi : value.length + labels.indexOf(b);
                return ai - bi;
            })}
            getOptionLabel={(option) => {
                return option.Nom + (!option.id ? " (Création)" : "");
            }}
            fullWidth
            groupBy={(option) => option.type}
            renderInput={(params) => (
                <TextField
                {...params}
                label="Etiquettes"
                variant="outlined"
                />
            )}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip label={option.Nom} {...getTagProps({ index })} disabled={fixedOptions.indexOf(option) !== -1} />
                ))
            }
            renderOption={(option, { selected }) => (
                <>
                <DoneIcon style={{ visibility: selected ? "visible" : "hidden" }} />
                <div style={{ color: !option.id/*creation*/ && "blue" }}>
                    {option.Nom}
                    <br />
                    <span style={{ fontSize: 10 }}>
                    {!option.id/*creation*/ ? "Création" : ""}
                    </span>
                </div>
                </>
            )}
            renderGroup={(params) => (
                  <div style={{ display: "inline-block", width: "50%", verticalAlign:"top"}}>
                    {params.children}
                  </div>
            )}
        />
    );
}
