import React, { useEffect, useState } from 'react';
import { Grid, Checkbox, FormControlLabel, Typography, Card, CardMedia, CardContent, CardActionArea} from '@material-ui/core';
import API from '../../services/API';
import LabelsPickerC from './components/LabelsPickerCustom';
import { Link } from 'react-router-dom';


export default function Gallerie({}){
    const [images, setImages] = useState([])
    const [checkboxState, setCheckboxState] = useState({pieces:true, createurs:true})
    const [imagesFiltrees, setImagesFiltrees] = useState([])
    const [filtres, setFiltres] = useState([])
    const [refresh, setRefresh] = useState(true)
    const [etiquettesExistantes, setEtiquettesExistantes] = useState([])

    useEffect(()=>{
        if(!refresh) return
        setRefresh(false)
        let promises=[]
        promises.push(API("GET","image/avecEtiquettes"))
        promises.push(API("GET","createur/avecEtiquettes"))
        promises.push(API("GET","etiquette"))

        Promise.all(promises).then(res=>{
            let createurs = res[1]
            createurs = createurs.map(c=>Object.assign(c,{type:"createur",Url:c.LogoUrl,Etiquettes: c.Etiquettes?[...c.Etiquettes.split(','),c.Nom]:[c.Nom]}))
            let etiquettesCreateurs = createurs.map(c=>({id:c.id, Nom:c.Nom, type:"Createur"}))

            let images = res[0]
            images = images.map(i=>{
                let createurImage = createurs.filter(c=>c.id===i.Createur_id).map(c=>c.Nom)
                return Object.assign(i,{type:"piece",Etiquettes: i.Etiquettes?[...i.Etiquettes.split(','),...createurImage]:createurImage})
            })

            let etiquettes = res[2]
            etiquettes = etiquettes.map(e=>Object.assign(e,{type:"standard"}))

            setImages([...images,...createurs])
            setEtiquettesExistantes([...etiquettes,...etiquettesCreateurs])
        })
    },[refresh])  

    const handleCheck = (event) => {
        setCheckboxState({ ...checkboxState, [event.target.name]: event.target.checked });
      };
    
    useEffect(()=>{
        let temp = images
        temp = temp.filter(x=> checkboxState.pieces&&x.type==="piece" || checkboxState.createurs&&x.type==="createur" )
        temp = temp.filter(i=>
            filtres.reduce((acc,f)=>
                acc && i.Etiquettes.includes(f)
            ,true)
        )
        setImagesFiltrees(temp)
    },[images, filtres, checkboxState])

    return(
        <>
            <Typography component="span">Rechercher dans </Typography>
            <FormControlLabel control={<Checkbox checked={checkboxState.pieces} onChange={handleCheck} name="pieces" color="primary" />} label="Pièces"/>
            <FormControlLabel control={<Checkbox checked={checkboxState.createurs} onChange={handleCheck} name="createurs" color="primary" />} label="Créateurs"/>
            <LabelsPickerC initialLabels={etiquettesExistantes} allowCreation={false} onChange={v=>setFiltres(v.map(r=>r.Nom))} fixedOptions={[]} />
            
            <Grid container spacing={2}>
                {imagesFiltrees.map((image) => (
                    <Grid item key={image.id} md={3} xs={12} style={{height:300}} >
                        <Card>
                            <CardActionArea component={Link}  to={(image.type==="createur")?"/createur/"+image.id:""}>
                                <CardMedia image={(image.Url !==null)?image.Url:"../images/no-image.png"} style={{height:0, paddingTop:"100%"}} />
                                {/*<CardContent>
                                    <h2>{createur.Nom}</h2>
                                </CardContent>*/}
                            </CardActionArea>
                        </Card>
                        {/*<img src={(image.Url !==null)?image.Url:"../images/no-image.png"} style={{maxWidth:"100%", maxHeight:"100%", display:"block", margin:"auto"}}/>*/}
                    </Grid>
                ))}
            </Grid>
        </>   
    )
}    