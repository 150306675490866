import React, { useEffect, useState } from "react";
import { Button, Fab, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Chip } from '@material-ui/core';
import { Add, Edit, Delete } from '@material-ui/icons';
import API from "../../../services/API";
import Input from "../../../components/Input";
import {useSnackbar} from 'notistack';  
import DragAndDrop from "../../../components/Drag&Drop";
import LabelsPicker from "../../../components/LabelsPicker";

import {PopupGenerale} from "../../Createurs/Createurs"




export default function BlocGeneral({createur, etiquettes, setReload}){
    const [popupOpen, setPopupOpen] = useState(false)
    const [etiquettesSelectionnees, setEtiquettesSelectionnees] = useState([])

    const { enqueueSnackbar } = useSnackbar();

    const resetPopup = ()=>{
        setPopupOpen(false)
        setReload(true)
    } 
    
    return(
        <>
            <Paper style={{position:"relative"}} >
                <Fab color="primary" style={{position: 'absolute', zIndex: 100, top: 10, right: 10}} onClick={()=>setPopupOpen(true)} ><Edit/></Fab>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <h3>Adresse : </h3>
                        <h4>{createur.Adresse}</h4>
                        <h3>Etiquettes : </h3>
                        {createur.Etiquettes && createur.Etiquettes.map(e=>(
                            <Chip key={e.id} label={e.Nom} />
                        ))}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <h3>Logo : </h3>
                        <img src={"../"+createur.LogoUrl} style={{maxWidth:"100%", maxHeight:200}} />
                    </Grid>
                </Grid>
            </Paper>
            {popupOpen && <PopupGenerale createur={createur} etiquettes={etiquettes} resetPopup={resetPopup} /> }
        </>
    )
}
