import React, {useState, useEffect} from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

export default function DatePickr({name, defaultValue, required, onChange}){ 
    const [value, setValue] = useState(defaultValue)
    const [error, setError] = useState(false) 

    /*useEffect(()=>{
        setValue(defaultValue)
    },[defaultValue])*/

    const handleChange=(date)=>{
        setValue(date)
        onChange(date)
        /*if(required && (Object.prototype.toString.call(date) !== "[object Date]" || isNaN(date.getTime()))){
            //setError(true)
        }else{
            setError(false)
        }
        
        if(date !== null){
            onChange(date)
        }*/
    }

    return(
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy" 
                margin="normal"
                label={name}
                value={value}
                autoOk={true}
                required={required}
                onChange={handleChange}
                KeyboardButtonProps={{}}
                error={error}
            />
        </MuiPickersUtilsProvider>  
    )
}