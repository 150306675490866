import React, { useEffect, useState } from "react";
import { Button, Fab, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@material-ui/core';
import { Add, Edit, Delete, Visibility } from '@material-ui/icons';
import MaterialTable from 'material-table'
import API from "../../../services/API";
import Input from "../../../components/Input";
import {useSnackbar} from 'notistack';  
import DatePickr from "../../../components/DatePickr";



export default function BlocEntretiens({idCreateur, entretiens, setReload}){
    const [popupOpen, setPopupOpen] = useState(false)
    const [popupData, setPopupData] = useState(undefined)

    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        { field: 'id', title: 'ID', hidden:true},
        { field: 'Date', title: 'Date' },
        { field: 'Lieu', title: 'Lieu' },
        { field: 'ODJ', title: 'Ordre du jour' },
        { field: 'CompteRendu', title: 'Compte rendu', hidden:true },
    ];
    const actions=[{
        icon:()=><Visibility/>,
        iconProps:{color:'primary'} ,
        tooltip:'Visualiser ligne',
        onClick:(event, rowData)=>handleOpen(rowData)
    },{
        icon:()=><Edit/>,
        iconProps:{color:'primary'} ,
        tooltip:'Editer ligne',
        onClick:(event, rowData)=>handleModify(rowData)
    },{
        icon:()=><Delete/>,
        iconProps:{color:'primary'} ,
        tooltip:'Supprimer ligne',
        onClick:(event, rowData)=>handleDelete(rowData)
    }]

    const handleOpen = (data)=>{
        setPopupData(data)
        setPopupOpen(true)
    }

    const handleModify = (data)=>{
        setPopupData(data)
        setPopupOpen(true)
    }

    const handleDelete = (data)=>{
        API("DELETE","Entretien",{id:data.id})
        .then(res=>{
            if(res.messageFr){//ERREUR
                enqueueSnackbar("Erreur lors de la suppression",{variant: "error"})
            }else{
                enqueueSnackbar("Suppression réussie",{variant: "success"})
            }
            resetPopup()
        })
    }

    const resetPopup = ()=>{
        setPopupData(undefined)
        setPopupOpen(false)
        setReload(true)
    }
    

    return(
        <>
            <Paper style={{position:"relative"}} >
                <Fab color="primary" style={{position: 'absolute', zIndex: 100, top: 10, right: 10}} onClick={()=>setPopupOpen(true)} ><Add/></Fab>
                <h2>Entretiens</h2>
                <MaterialTable data={entretiens} columns={columns} options={{search: false, showTitle:false, paging:false}} actions={actions}/>
            </Paper>
            {popupOpen && <PopupCreationEntretien initialValues={popupData} idCreateur={idCreateur} resetPopup={resetPopup} /> }
        </>
    )
}

function PopupCreationEntretien({initialValues, idCreateur, resetPopup}){
    const [reponses, setReponses] = useState(initialValues || {Date:(new Date()).toJSON().slice(0,10), Lieu:undefined, Createur_id:idCreateur})
    
    const { enqueueSnackbar } = useSnackbar();

    const handleChange = (key, value)=>{
        setReponses(curr=>({...curr,[key]:value}))
    }

    const formValid = (initialValues===undefined && Object.values(reponses).reduce((acc,x)=>acc&&x!==undefined, true)) || (initialValues!==undefined && !isEqual(initialValues,reponses))

    const submitForm = (event)=>{
        const methode = (initialValues===undefined)?"POST":"PATCH"
        API(methode, "entretien", reponses)
        .then(res=>{
            if(res.messageFr){
                enqueueSnackbar("Erreur lors de la création de l'entretien : "+res.messageFr,{variant: "error"})
            }else{
                enqueueSnackbar("Création de l'entretien réussie",{variant: "success"})
            }
            resetPopup()
        })
    }

    return(
        <Dialog open={true} aria-labelledby="popup" fullWidth={true} maxWidth='md'>
            <DialogTitle id="popup">Créer un entretien</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <DatePickr name="Date" defaultValue={new Date()} required={true} onChange={(value)=>handleChange("Date", value.toJSON().slice(0,10))} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input name="Lieu" defaultValue={initialValues && initialValues.Lieu} required={true} onChange={(value)=>handleChange("Lieu", value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <Input name="Ordre du jour" defaultValue={initialValues && initialValues.ODJ} onChange={(value)=>handleChange("ODJ", value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <Input name="CompteRendu" fullWidth={true} defaultValue={initialValues && initialValues.CompteRendu} multiline={true} onChange={(value)=>handleChange("CompteRendu", value)} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={resetPopup} variant="contained" color="secondary">
                    Annuler
                </Button>
                <Button disabled={!formValid} onClick={submitForm} variant="contained" color="primary" autoFocus>
                    Ajouter
                </Button>
            </DialogActions>
        </Dialog>
    )
}




function isEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
  
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  }